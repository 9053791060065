import { useEffect } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { ISingleDoctorInfoResp, ISinglePatientDataResp } from "../../services/interfaces";
import { useEditUserMutation } from "../../services/Requsts/userApi";
import { IDoctorUserEditFormValues } from "../interfaces";
import { useGetDoctorsListQuery } from "../../services/Requsts/doctorsApi";
import { useGetPatientsListQuery } from "../../services/Requsts/patientApi";

interface IProps {
  userSingleData: ISingleDoctorInfoResp | ISinglePatientDataResp | undefined;
}

const UseUserHooks = (props: IProps) => {
  const [editDoctorUser, { isLoading, isSuccess }] = useEditUserMutation();

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    trigger,
  } = useForm<FieldValues | IDoctorUserEditFormValues>();

  const onSubmit = (data: FieldValues | IDoctorUserEditFormValues) => {
    editDoctorUser({
      id: props?.userSingleData?.result?.user?.id,
      payload: data,
    });
  };

  const { refetch: refetchDoctors } = useGetDoctorsListQuery({page: 1, filter: JSON.stringify({status: props?.userSingleData?.result?.user?.status})}, {
    skip: !isSuccess,
  });
  
  const { refetch: refetchPatients } = useGetPatientsListQuery({page: 1, filter: JSON.stringify({status: props?.userSingleData?.result?.user?.status})}, {
    skip: !isSuccess,
  });

  useEffect(() => {
    if (isSuccess) {
      toast.success("Բժշկի անձնական ինֆորմացիան հաջողությամբ թարմացվել է");
      refetchDoctors();
      refetchPatients();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (props?.userSingleData) {
      setValue("avatar", props?.userSingleData?.result?.user?.avatar);
      setValue("bio", props?.userSingleData?.result?.user?.bio);
      setValue("first_name", props?.userSingleData?.result?.user?.first_name);
      setValue("last_name", props?.userSingleData?.result?.user?.last_name);
      setValue("birth_day", props?.userSingleData?.result?.user?.birth_day);
      setValue(
        "phone_number",
        props?.userSingleData?.result?.user?.phone_number
          ? props?.userSingleData?.result?.user?.phone_number
          : ""
      );
    }
  }, [props?.userSingleData]);  

  return {
    handleSubmit,
    setValue,
    onSubmit,
    errors,
    control,
    isLoading,
    trigger 
  };
};

export default UseUserHooks;
