import { useCallback, useEffect } from "react";
import { FieldValues, useFieldArray, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  IAppGoalsData,
  ICertificateFields,
  IEditDoctorForm,
  ILicenseFields,
} from "../../interfaces";
import { ISingleDoctorInfoResp } from "../../../services/interfaces";
import { useEditDoctorMutation } from "../../../services/Requsts/doctorsApi";
import { experienceData } from "../../../utils/generalData";

interface IProps {
  doctorSingleData: ISingleDoctorInfoResp | undefined;
}

const UseDoctorAdditionalInfoHooks = (props: IProps) => {
  const [editDoctorReq, { isLoading, isSuccess }] = useEditDoctorMutation();

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    getValues,
  } = useForm<FieldValues | IEditDoctorForm>({
    defaultValues: {
      institution: props?.doctorSingleData?.result?.institution,
      specialization: props?.doctorSingleData?.result?.specialization?.id,
      specialization_other:
        props?.doctorSingleData?.result?.specialization?.id === "other"
          ? props?.doctorSingleData?.result?.specialization?.label
          : "",
      experience: props?.doctorSingleData?.result?.experience?.id,
      clinics: props?.doctorSingleData?.result?.clinics
    ? props.doctorSingleData.result.clinics.map(clinic => clinic.id)
    : [],
      license: props?.doctorSingleData?.result?.license,
      certifications: props?.doctorSingleData?.result?.certifications,
      app_goals: props?.doctorSingleData?.result?.app_goals?.map(
        (item: IAppGoalsData) => (item.id === "other" ? item.id : item.label)
      ),
      app_goal_other:
        props?.doctorSingleData?.result?.app_goals?.find(
          (item: IAppGoalsData) => item?.id === "other"
        )?.label || "",
    },
  });

  const {
    fields: licenseFields,
    append,
    remove: removeLicense,
  } = useFieldArray<FieldValues | ILicenseFields>({
    name: "license",
    control,
  });

  const {
    fields: certificateFields,
    append: appendCertificate,
    remove: removeCertificate,
  } = useFieldArray<FieldValues | ICertificateFields>({
    name: "certifications",
    control,
  });

  const onSubmit = (data: FieldValues | IEditDoctorForm) => {
    const payload = {
      institution: data?.institution,
      position: data?.position,
      specialization:
        data?.specialization === "other"
          ? { id: "other", label: data?.specialization_other }
          : {
              id: data?.specialization,
              label: data?.specialization?.replace("_", " "),
            },
      clinics: data?.clinics?.map((clinic: string) => {
        return {
          id: clinic,
          label: clinic,
        };
      }),
      experience: experienceData?.filter(
        (item) => item?.id === data?.experience
      )[0],
      license: data?.license,
      certifications: data?.certifications,
      app_goals: data?.app_goals?.map((goal: string) => {
        return {
          id: goal,
          label: goal === "other" ? data?.app_goal_other : goal,
        };
      }),
    };

    editDoctorReq({
      id: props?.doctorSingleData?.result?.id,
      payload: payload,
    });
  };

  useEffect(() => {
    if (props?.doctorSingleData) {
      setValue("institution", props?.doctorSingleData?.result?.institution);
      setValue("position", props?.doctorSingleData?.result?.position);
      setValue("clinics", props?.doctorSingleData?.result?.clinics);
    }
  }, [props?.doctorSingleData]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(
        "Բժշկի մասին հավելյալ տեղեկատվությունը հաջողությամբ թարմացված է"
      );
    }
  }, [isSuccess]);

  const addNewLicenseField = useCallback(() => {
    append({ file: {}, licenseNumber: null });
  }, [append]);

  const addNewCertificateField = useCallback(() => {
    appendCertificate({ file: {}, info: "" });
  }, [appendCertificate]);

  return {
    errors,
    control,
    isLoading,
    licenseFields,
    certificateFields,
    addNewLicenseField,
    addNewCertificateField,
    removeCertificate,
    removeLicense,
    handleSubmit,
    onSubmit,
    getValues,
    setValue,
    watch,
  };
};

export default UseDoctorAdditionalInfoHooks;
