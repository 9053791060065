import { ClipLoader } from "react-spinners";
import { Box, Tab, Tabs } from "@mui/material";
import {
  CustomPagination,
  CustomTabPanel,
  DoctorsFiltration,
  EmptyData,
  ErrorComponent,
  SearchInput,
  UsersTable,
} from "../../components";
import UseCustomModalHooks from "../../components/CustomModal/customModal.hooks";
import { PagesEnum } from "../../components/interfaces";
import UseDoctorsHooks from "./doctors.hooks";
import styles from "./doctors.module.scss";

const DoctorsPage: React.FC = () => {
  const { open, handleClose, handleClickOpen } = UseCustomModalHooks();
  const {
    page,
    value,
    status,
    errors,
    control,
    doctorsList,
    filteredGoals,
    getDoctorsListError,
    getDoctorsListLoading,
    filteredSpecializations,
    changeDoctorStatusLoading,
    changeDoctorStatusReq,
    setSearchInputValue,
    handlePageChange,
    handleSubmit,
    handleChange,
    a11yProps,
    onSubmit,
  } = UseDoctorsHooks({ handleClose });

  if (getDoctorsListLoading) {
    return (
      <div className="loader-page">
        <ClipLoader color={"grey"} size={50} />
      </div>
    );
  }

  if (getDoctorsListError) {
    return (
      <div className="loader-page">
        <ErrorComponent />
      </div>
    );
  }

  return (
    <div className="outlet-page">
      <div className={styles["headin-container"]}>
        <h1 className="page-heading">Ծանոթացեք բժիշկների ցանկին</h1>
        <SearchInput setSearchInputValue={setSearchInputValue} />
      </div>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Ակտիվ բժիշկների ցանկ" {...a11yProps(0)} />
            <Tab label="Չհաստատված բժիշկների ցանկ" {...a11yProps(1)} />
            <Tab label="Ջնջված բժիշկների ցանկ" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <DoctorsFiltration
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            control={control}
            errors={errors}
            filteredSpecializations={filteredSpecializations}
            loading={false}
            filteredGoals={filteredGoals}
          />
          {getDoctorsListLoading ? (
            <div className="loader-page">
              <ClipLoader color={"grey"} size={50} />
            </div>
          ) : (
            <>
              {doctorsList?.result?.count === 0 ? (
                <div className="loader-page">
                  <EmptyData />
                </div>
              ) : (
                <>
                  <UsersTable
                    handleClickOpen={handleClickOpen}
                    data={doctorsList?.result?.rows}
                    pageForNavigation={PagesEnum.DOCTORS}
                    handleClose={handleClose}
                    loading={changeDoctorStatusLoading}
                    open={open}
                    changeUserStatusReq={changeDoctorStatusReq}
                    activeStatus={status}
                  />
                  {doctorsList?.result?.count! > 10 && (
                    <CustomPagination
                      count={Math.ceil(doctorsList?.result?.count! / 10)}
                      onChange={handlePageChange}
                      page={page}
                    />
                  )}
                </>
              )}
            </>
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <DoctorsFiltration
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            control={control}
            errors={errors}
            filteredSpecializations={filteredSpecializations}
            loading={false}
            filteredGoals={filteredGoals}
          />
          {getDoctorsListLoading ? (
            <div className="loader-page">
              <ClipLoader color={"grey"} size={50} />
            </div>
          ) : (
            <>
              {doctorsList?.result?.count === 0 ? (
                <div className="loader-page">
                  <EmptyData />
                </div>
              ) : (
                <>
                  <UsersTable
                    handleClickOpen={handleClickOpen}
                    data={doctorsList?.result?.rows}
                    pageForNavigation={PagesEnum.DOCTORS}
                    handleClose={handleClose}
                    loading={changeDoctorStatusLoading}
                    open={open}
                    changeUserStatusReq={changeDoctorStatusReq}
                    activeStatus={status}
                  />
                  {doctorsList?.result?.count! > 10 && (
                    <CustomPagination
                      count={Math.ceil(doctorsList?.result?.count! / 10)}
                      onChange={handlePageChange}
                      page={page}
                    />
                  )}
                </>
              )}
            </>
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <DoctorsFiltration
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            control={control}
            errors={errors}
            filteredSpecializations={filteredSpecializations}
            loading={false}
            filteredGoals={filteredGoals}
          />
          {getDoctorsListLoading ? (
            <div className="loader-page">
              <ClipLoader color={"grey"} size={50} />
            </div>
          ) : (
            <>
              {doctorsList?.result?.count === 0 ? (
                <div className="loader-page">
                  <EmptyData />
                </div>
              ) : (
                <>
                  <UsersTable
                    handleClickOpen={handleClickOpen}
                    data={doctorsList?.result?.rows}
                    pageForNavigation={PagesEnum.DOCTORS}
                    handleClose={handleClose}
                    loading={changeDoctorStatusLoading}
                    open={open}
                    changeUserStatusReq={changeDoctorStatusReq}
                    activeStatus={status}
                  />
                  {doctorsList?.result?.count! > 10 && (
                    <CustomPagination
                      count={Math.ceil(doctorsList?.result?.count! / 10)}
                      onChange={handlePageChange}
                      page={page}
                    />
                  )}
                </>
              )}
            </>
          )}
        </CustomTabPanel>
      </Box>
    </div>
  );
};

export default DoctorsPage;
