import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormHandleSubmit,
} from "react-hook-form";
import { ButtonTypeEnum, DoctorFiltrationValues, IOption } from "../interfaces";
import {CustomSelect} from "../index";
import {CustomButton} from "../index";
import {CustomMultiSelect} from "../index";
import { clinics, experienceData } from "../../utils/generalData";
import styles from "./doctorsFiltration.module.scss";

interface IProps {
  handleSubmit: UseFormHandleSubmit<
    FieldValues | DoctorFiltrationValues,
    undefined
  >;
  onSubmit: (data: FieldValues | DoctorFiltrationValues) => void;
  control: Control<FieldValues | DoctorFiltrationValues, any>;
  errors: FieldErrors<FieldValues | DoctorFiltrationValues>;
  filteredSpecializations: IOption[];
  loading: boolean;
  filteredGoals: IOption[]
}

const DoctorsFiltration: React.FC<IProps> = (props) => {
  const {
    handleSubmit,
    onSubmit,
    control,
    errors,
    filteredSpecializations,
    loading,
    filteredGoals
  } = props;
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles["doctor-filtration__form"]}>
      <CustomSelect
        controllerName="specialization"
        control={control}
        controllerRules={{ required: false }}
        label="Մասնագիտացումը"
        data={filteredSpecializations}
        errors={errors}
      />
      <CustomMultiSelect
        controllerName="clinics"
        control={control}
        controllerRules={{ required: false }}
        label="Կլինիկաներ"
        data={clinics}
        errors={errors}
      />
      <CustomSelect
        controllerName="experience"
        control={control}
        controllerRules={{ required: false }}
        label="Փորձ"
        data={experienceData}
        errors={errors}
      />
      <CustomMultiSelect
        controllerName="app_goals"
        control={control}
        controllerRules={{ required: false }}
        label="Նպատակները"
        data={filteredGoals}
        errors={errors}
      />
      <CustomButton
        buttonText={"Կիրառել"}
        type={ButtonTypeEnum.submit}
        onClick={() => {}}
        loading={loading}
      />
    </form>
  );
};

export default DoctorsFiltration;
