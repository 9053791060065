import { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { IReasonFormValues } from "../../components/interfaces";
import {
  useAddReasonMutation,
  useEditReasonMutation,
  useGetReasonsListQuery,
} from "../../services/Requsts/reasonsApi";

interface IProps {
  handleClose: () => void;
}

const UseReasonHooks = (props: IProps) => {
  const [editReason, setEditReason] = useState<boolean>(false);

  const {
    data: reasonsList,
    isError: reasonsListError,
    isLoading: reasonsListLoading,
  } = useGetReasonsListQuery();

  const [
    editReasnReq,
    {
      isLoading: reasonsEditLoading,
      isSuccess: reasonsEditSuccess,
    },
  ] = useEditReasonMutation();

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm<FieldValues | IReasonFormValues>();

  const [
    addReasonReq,
    { isSuccess: addedReasonSuccessfully, isLoading: addReasonLoading },
  ] = useAddReasonMutation();

  const onSubmit = (data: FieldValues) => {
    const payload = {
      tag: data?.tag,
      title: {
        en: data?.title_en,
        ru: data?.title_ru,
        am: data?.title_am,
      },
    };
    editReason ? editReasnReq(payload) : addReasonReq(payload);
  };

  useEffect(() => {
    if (addedReasonSuccessfully || reasonsEditSuccess) {
      toast.success("Գործողությունը հաջողությամբ կատարվել է");
      props?.handleClose();
    }
  }, [addedReasonSuccessfully || reasonsEditSuccess]);
  

  useEffect(() => {
    if (editReason) {
      setValue("tag", "tag");
      setValue("title_en", "title_en");
      setValue("title_ru", "title_ru");
      setValue("title_am", "title_am");
    }
  }, [editReason]);

  return {
    reset,
    onSubmit,
    handleSubmit,
    setEditReason,
    errors,
    control,
    editReason,
    reasonsList,
    reasonsListError,
    addReasonLoading,
    reasonsListLoading,
  };
};

export default UseReasonHooks;
