import { Control, Controller, FieldError, FieldErrors, FieldValues } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { IControllerRules } from '../interfaces';
import 'react-phone-input-2/lib/style.css';
import styles from './customPhoneInput.module.scss';

interface IProps {
  label?: string;
  defaultValue?: any;
  controllerName: string;
  controllerRules: IControllerRules;
  errors?: FieldErrors<FieldValues>;
  control: Control<FieldValues, any>;
}

const CustomPhoneInput: React.FC<IProps> = (props) => {
  const {
    label,
    errors,
    control,
    controllerName,
    controllerRules,
  } = props;
  return (
    <div className={styles['input-container']}>
      {label && <label className={styles['label']}>{label}</label>}
      <Controller
        key={controllerName}
        control={control}
        name={controllerName}
        rules={controllerRules}
        render={({ field: { onChange, name, value } }) => {
          return (
            <PhoneInput
              country={'am'}
              value={value}
              onChange={(phone) => {onChange(phone)}}
              enableAreaCodes={true}
              containerClass='custom-phone-input-container'
              inputClass='custom-phone-input'
              buttonClass='custom-flag-button'
              masks={{ am: '(..) ..-..-..' }}
              placeholder='+374'
            />
          );
        }}
      />
      {(errors?.[controllerName] as FieldError)?.message && (
        <label className={styles["input-error"]}>
          {(errors?.[controllerName] as FieldError).message}
        </label>
      )}
    </div>
  );
};

export default CustomPhoneInput;
