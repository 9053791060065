import { Control, Controller, FieldError, FieldErrors, FieldValues } from "react-hook-form";
import { MenuItem, Select } from "@mui/material";
import { IControllerRules, ISelectData } from "../interfaces";
import styles from "./customSelect.module.scss";

interface IProps {
  errors?: FieldErrors<FieldValues>;
  label: string;
  data: ISelectData[];
  controllerName: string;
  control: Control<FieldValues, any>;
  controllerRules: IControllerRules;
}

const CustomSelect: React.FC<IProps> = (props) => {
  const { label, control, controllerName, controllerRules, errors, data } = props;

  return (
    <div className={styles["select-container"]}>
      {label && <label className={styles["label"]}>{label}</label>}
      <Controller
        key={controllerName}
        control={control}
        name={controllerName}
        rules={controllerRules}
        render={({ field: { onChange, name, value } }) => {
          return (
            <div className="custom-select">
              <Select
                value={value}
                onChange={onChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "4px 10px", 
                    borderRadius: "8px", 
                    border: "2px solid $light-grey", 
                    
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent", 
                      border: "2px solid $light-grey"
                    },
      
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "$light-grey", 
                      border: "2px solid $light-grey"
                    },
      
                    input: {
                      padding: "0 !important", 
                    },
                  },
                }}
              >
                {data?.map((item: ISelectData, index: number) => {
                  return (
                    <MenuItem value={item?.id} key={index}>
                      {item?.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          );
        }}
      />
      {(errors?.[controllerName] as FieldError)?.message && (
        <label className={styles["input-error"]}>
          {(errors?.[controllerName] as FieldError).message}
        </label>
      )}
    </div>
  );
};

export default CustomSelect;
