import { useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import {
  useGetReportByIdQuery,
  useGetReportReasonsQuery,
  useGetReportsListQuery,
} from "../../services/Requsts/reportsApi";
import UseGeneralHooks from "../../utils/generalHooks";
import { useDeletePostMutation } from "../../services/Requsts/postApi";

interface IProps {
  handleClose: () => void;
}

const UseReportsSinglePageHooks = (props: IProps) => {
  const { params, navigate } = UseGeneralHooks();

  const {
    data: reportByIdInfo,
    isLoading: reportByIdLoading,
    isError: reportByIdError,
  } = useGetReportByIdQuery(params?.id!);

  const { data: reportReasonsList, isLoading: reportReasonsLoading } =
    useGetReportReasonsQuery();

  const [
    deletePostReq,
    { isLoading: deletePostLoading, isSuccess: postIsDeletedSucessfully },
  ] = useDeletePostMutation();

  const {refetch} = useGetReportsListQuery()  

  const reportsData = useMemo(() => {
    return [
      {
        id: 1,
        label: "Բողոքարկման հեղինակը՝",
        content: `${reportByIdInfo?.result?.user?.first_name} ${reportByIdInfo?.result?.user?.last_name}`,
      },
      {
        id: 2,
        label: "Բողոքարկման պարունակությունը՝",
        content: reportByIdInfo?.result?.reason?.title?.en,
      },
      {
        id: 3,
        label: "Փոստի հեղինակը՝",
        content: `${reportByIdInfo?.result?.post?.user?.first_name} ${reportByIdInfo?.result?.post?.user?.last_name}`,
      },
    ];
  }, [reportByIdInfo || []]);

  useEffect(() => {
    if (postIsDeletedSucessfully) {
      toast.success("Փոստը հաջողությամբ ջնջված է");
      props?.handleClose();
      navigate("/main/reports");
      refetch()
    }
  }, [postIsDeletedSucessfully]);

  return {
    reportsData,
    reportByIdInfo,
    reportByIdError,
    reportByIdLoading,
    reportReasonsList,
    deletePostLoading,
    reportReasonsLoading,
    deletePostReq,
  };
};

export default UseReportsSinglePageHooks;
