import { useEffect, useMemo, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useGetDoctorsListQuery } from "../../services/Requsts/doctorsApi";
import {
  DoctorFiltrationValues,
  StatusesEnum,
} from "../../components/interfaces";
import { useChangeUserStatusMutation } from "../../services/Requsts/userApi";
import {
  appGoals,
  clinics,
  experienceData,
  specializations,
} from "../../utils/generalData";

interface IProps {
  handleClose: () => void;
}

const UseDoctorsHooks = (props: IProps) => {
  const [page, setPage] = useState<number>(1);
  const [status, setStatus] = useState<StatusesEnum>(StatusesEnum?.ACTIVE);
  const [value, setValue] = useState<number>(0);
  const [searchInputValue, setSearchInputValue] = useState<string>("");
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>("");

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchValue(searchInputValue);
    }, 500);

    return () => clearTimeout(handler);
  }, [searchInputValue]);

  const filteredSpecializations = useMemo(() => {
    return specializations.filter((spec) => spec.id !== "other");
  }, []);

  const filteredGoals = useMemo(() => {
    return appGoals.filter((item) => item.id !== "other");
  }, []);

  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
  } = useForm<FieldValues | DoctorFiltrationValues>();

  const onSubmit = (data: FieldValues | DoctorFiltrationValues) => {};

  const filterValues = getValues();

  const filter = {
    status: status,
    ...(filterValues.specialization && {
      specialization: specializations
        .filter((spec) => spec.id === filterValues.specialization)
        .map((spec) => ({
          id: spec.id,
          label: spec.label,
        }))[0],
    }),
    ...(filterValues.experience && {
      experience: experienceData
        .filter((item) => item.id === filterValues.experience)
        .map((mappedItem) => ({
          id: mappedItem.id,
          label: mappedItem.label,
        }))[0],
    }),
    ...(filterValues.app_goals && {
      app_goals: appGoals.filter((goal) =>
        filterValues.app_goals.includes(goal.id)
      ),
    }),
    ...(filterValues.clinics && {
      clinics: clinics.filter((clinic) =>
        filterValues.clinics.includes(clinic.id)
      ),
    }),
  };

  const {
    data: doctorsList,
    refetch,
    isLoading: getDoctorsListLoading,
    isError: getDoctorsListError,
  } = useGetDoctorsListQuery({
    page,
    filter: JSON.stringify(filter),
    search: debouncedSearchValue,
  });

  const [
    changeDoctorStatusReq,
    {
      isLoading: changeDoctorStatusLoading,
      isSuccess: changeDoctorStatusSuccess,
    },
  ] = useChangeUserStatusMutation();

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  useEffect(() => {
    if (changeDoctorStatusSuccess) {
      props?.handleClose();
      toast.success("Տվյալ բժիշկի կարգավիճակ հաջողությամբ փոխված է");
      refetch();
    }
  }, [changeDoctorStatusSuccess]);

  useEffect(() => {
    refetch;
  }, [status]);

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (newValue === 0) {
      setStatus(StatusesEnum?.ACTIVE);
    } else if (newValue === 1) {
      setStatus(StatusesEnum?.PENDING);
    } else if (newValue === 2) {
      setStatus(StatusesEnum?.DELETED);
    }
  };

  return {
    page,
    value,
    status,
    errors,
    control,
    doctorsList,
    filteredGoals,
    getDoctorsListError,
    getDoctorsListLoading,
    filteredSpecializations,
    changeDoctorStatusLoading,
    changeDoctorStatusSuccess,
    changeDoctorStatusReq,
    setSearchInputValue,
    handlePageChange,
    handleSubmit,
    handleChange,
    a11yProps,
    onSubmit,
    setPage,
  };
};

export default UseDoctorsHooks;
