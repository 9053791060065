import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useAppDispatch } from "../services/store";

const UseGeneralHooks = () => {
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const dispatch = useAppDispatch()
    const access_token = localStorage.getItem("armbionics-access-token");

    const formatDate = (isoString: string) => {
        const date = new Date(isoString);
        const formattedWithSlice = date.toISOString().slice(0, 10);      
        return formattedWithSlice; 
      };

    return {
        navigate, params, access_token, formatDate, location, dispatch
    }
}
export default UseGeneralHooks;