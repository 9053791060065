import { useState } from "react";
import { downloadImageFromBunny } from "../../utils/downloadImgFromBunny";
import { IUsersListResultRow } from "../../services/interfaces";
import userImg from "../../assets/images/Portrait_Placeholder.png";
import { ButtonTypeEnum, PagesEnum, StatusesEnum } from "../interfaces";
import UseGeneralHooks from "../../utils/generalHooks";
import { CustomModal, CustomButton } from "../index";
import styles from "./usersTable.module.scss";

interface IProps {
  data: IUsersListResultRow[] | undefined;
  pageForNavigation: PagesEnum;
  handleClickOpen: () => void;
  changeUserStatusReq: any;
  handleClose: () => void;
  loading: boolean;
  open: boolean;
  activeStatus: StatusesEnum;
}

const UsersTable: React.FC<IProps> = (props) => {
  const { navigate, formatDate } = UseGeneralHooks();
  const {
    data,
    open,
    loading,
    activeStatus,
    pageForNavigation,
    changeUserStatusReq,
    handleClickOpen,
    handleClose,
  } = props;

  const [selectedUserId, setSelectedUserId] = useState<number>()

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th>Ավատար</th>
            <th>Անուն</th>
            <th>Ազանուն</th>
            <th>Ծննդյան ամսաթիվ</th>
            <th>Էլ հասցե</th>
            <th>Հեռախոսահամար</th>
            <th>Գործողություն</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item: IUsersListResultRow, index: number) => {
            return (
              <>
                <tr
                  key={index}
                  onClick={() =>
                    item?.user?.status === StatusesEnum?.ACTIVE &&
                    navigate(`/main/${pageForNavigation}/${item?.id}`)
                  }
                  className="table-row"
                >
                  <td className={styles["user-avatar__container"]}>
                    <span>{index + 1}</span>{" "}
                    <img
                      src={
                        item?.user?.avatar
                          ? downloadImageFromBunny({
                              avatar: item?.user?.avatar,
                            })
                          : userImg
                      }
                      className={styles["user-avatar"]}
                    />{" "}
                  </td>
                  <td>
                    {item?.user?.first_name ? item?.user?.first_name : "---"}
                  </td>
                  <td>
                    {item?.user?.last_name ? item?.user?.last_name : "---"}
                  </td>
                  <td>
                    {item?.user?.birth_day
                      ? formatDate(item?.user?.birth_day)
                      : "---"}
                  </td>
                  <td>{item?.user?.email ? item?.user?.email : "---"}</td>
                  <td>
                    {item?.user?.phone_number && "+"}
                    {item?.user?.phone_number
                      ? item?.user?.phone_number
                      : "---"}
                  </td>
                  <td>
                    <CustomButton
                      buttonText={
                        item?.user?.status === StatusesEnum?.ACTIVE
                          ? "Ջնջել"
                          : item?.user?.status === StatusesEnum?.PENDING
                          ? "Ընթացքի մեջ"
                          : "Վերականգնել"
                      }
                      type={ButtonTypeEnum.button}
                      loading={loading}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClickOpen();
                        setSelectedUserId(+item?.user?.id)
                      }}
                      className={
                        item?.user?.status === StatusesEnum?.ACTIVE
                          ? "delete-btn"
                          : item?.user?.status === StatusesEnum?.DELETED
                          ? "restore-btn"
                          : ""
                      }
                      disabled={item?.user?.status === StatusesEnum?.PENDING}
                    />
                  </td>
                </tr>
              </>
            );
          })}
          <CustomModal
            open={open}
            handleClose={handleClose}
            onConfirm={() =>
              changeUserStatusReq({
                id: selectedUserId,
                status:
                  activeStatus === StatusesEnum?.ACTIVE
                    ? StatusesEnum.DELETED
                    : StatusesEnum?.PENDING,
              })
            }
            loading={loading}
            title={
              activeStatus === StatusesEnum?.ACTIVE
                ? "Ցանկանում եք ջնջել տվյալ բժիշկին"
                : "Ցանկանում եք վերականգնե՞լ տվյալ բժիշկին"
            }
          ></CustomModal>
        </tbody>
      </table>
    </div>
  );
};

export default UsersTable;
