import {
  CustomPhoneInput,
  CustomDatePicker,
  CustomInput,
  CustomButton,
  CustomTextfield,
  EditEmail,
  ImageUpload,
} from "../index";
import { ISingleDoctorInfoResp, ISinglePatientDataResp } from "../../services/interfaces";
import { ButtonTypeEnum, InputTypeEnum } from "../interfaces";
import UseUserHooks from "./userEdit.hooks";
import styles from "./userEdit.module.scss";

interface IProps {
  userSingleData: ISingleDoctorInfoResp | ISinglePatientDataResp | undefined;
}

const UserEdit: React.FC<IProps> = (props) => {
  const { handleSubmit, errors, control, setValue, onSubmit, isLoading, trigger  } =
  UseUserHooks({ userSingleData: props?.userSingleData });    

  return (
    <div className={styles["container"]}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={styles["user-edit-form"]}
      >
        <div className={styles["profile-img__container"]}>
          <ImageUpload
            control={control}
            setValue={setValue}
            controllerName={"avatar"}
            defaultValue={props?.userSingleData?.result?.user?.avatar}
          />
        </div>
        <div className={styles["personal-info"]}>
          <div className={styles["row"]}>
            <CustomInput
              type={InputTypeEnum.text}
              controllerName="first_name"
              control={control}
              controllerRules={{ required: "Պարտադիրն է" }}
              label="Անուն"
              errors={errors}
            />
            <CustomInput
              type={InputTypeEnum.text}
              controllerName="last_name"
              control={control}
              controllerRules={{ required: "Պարտադիրն է" }}
              label="Ազգանուն"
              errors={errors}
            />
          </div>
          <div className={styles["row"]}>
            <CustomPhoneInput
              controllerName="phone_number"
              control={control}
              controllerRules={{
                pattern: {
                  value: /^374\d{8}$/, 
                  message:
                    "Անվավեր էլ հասցե",
                },
                required: false
              }}
              label="Հեռախոսահամար"
              errors={errors}
            />
            <CustomDatePicker
              controllerName="birth_day"
              control={control}
              controllerRules={{ required: "Պարտադիրն է" }}
              label="Ծննդյան տարեթիվ"
              errors={errors}
            />
          </div>
          <div className={styles["row"]}>
            <CustomTextfield
              controllerName="bio"
              control={control}
              controllerRules={{ required: false }}
              label="Իմ մասին"
              errors={errors}
            />
          </div>
          <CustomButton
            buttonText={"Փոփոխել"}
            type={ButtonTypeEnum.submit}
            loading={isLoading}
          />
        </div>
      </form>
      {/* <EditEmail
        emailDefaultValue={props?.userSingleData?.result?.user?.email}
        id={props?.userSingleData?.result?.user?.id}
      /> */}
    </div>
  );
};

export default UserEdit;
