import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../API";
import { IReasonsListResp } from "../interfaces";

interface ICreateReasonPayload {
  tag: string;
  title: {
    en: string;
    ru: string;
    am: string;
  };
}

export const reasonsApi = createApi({
  reducerPath: "reasonsApi",
  tagTypes: ["reasons"],
  baseQuery: axiosBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  endpoints: (builder) => ({
    getReasonsList: builder.query<IReasonsListResp, void>({
      query() {
        return {
          url: `/post/admin/reasons`,
          method: "GET",
        };
      },
      providesTags: ["reasons"],
    }),
    getReasonById: builder.query<ICreateReasonPayload, string>({
      query(id) {
        return {
          url: `/post/admin/reasons/${id}`,
          method: "GET",
        };
      },
      providesTags: ["reasons"],
    }),
    addReason: builder.mutation<IReasonsListResp, ICreateReasonPayload>({
      query(payload) {
        return {
          url: `/post/report/reasons`,
          data: payload,
          method: "POST",
        };
      },
      invalidatesTags: ["reasons"],
    }),
    deleteReason: builder.mutation<number, string>({
      query(id) {
        return {
          url: `/post/report/reasons/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["reasons"],
    }),
    editReason: builder.mutation<IReasonsListResp, ICreateReasonPayload>({
      query(payload) {
        return {
          url: `/post/report/reasons`,
          data: payload,
          method: "PUT",
        };
      },
      invalidatesTags: ["reasons"],
    }),
  }),
});

export const {
  useAddReasonMutation,
  useEditReasonMutation,
  useGetReasonByIdQuery,
  useGetReasonsListQuery,
  useDeleteReasonMutation,
} = reasonsApi;
